import { buildCollection, EntityCollection } from '@camberi/firecms'
import saleSchema, { LightSale } from 'src/collections/sale/saleSchema'
import { salesSearchDelegate } from 'src/utils/algolia'

const saleCollection: EntityCollection<LightSale> = buildCollection({
  name: 'Ventes',
  relativePath: 'sales',
  schema: saleSchema,
  group: 'Données',
  permissions: ({ user, authController }) => ({
    edit: true,
    create: true,
    delete: true
  }),
  textSearchDelegate: salesSearchDelegate,
  excludedProperties: ['updatedAt'],
  defaultSize: 'xs',
  initialSort: ['createdAt', 'desc']
})

export default saleCollection
