import React, { useState } from 'react'
import getFunctionCallable from 'src/utils/getFunctionCallable'
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Paper } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

type TaskToolType = {
  functionName: string
}

export default function IntegrityCheckCard({ functionName }: TaskToolType) {
  const classes = useCardStyles()

  const [isDeepCheck, setIsDeepCheck] = useState<true | false>(false)
  const [isSubmitting, setIsSubmitting] = useState<true | false>(false)
  const [resultSuccess, setResultSuccess] = useState<string>('')
  const [resultError, setResultError] = useState<string>('')

  const handleTask = async () => {
    setResultSuccess('')
    setResultError('')
    const taskHandler = getFunctionCallable(functionName)
    setIsSubmitting(true)
    try {
      // we just extract the ids from the array of users
      const result = await taskHandler({ deepCheck: isDeepCheck })
      setResultSuccess(JSON.stringify(result, null, 2))
      setIsSubmitting(false)
    } catch (e) {
      console.error(e)
      setResultError(JSON.stringify(e.message, null, 2))
      setIsSubmitting(true)
    }
  }

  return (
    <>
      <Paper className={'classes.paper'} style={{ padding: '16px' }}>
        <div className={classes.container}>
          <Button disabled={isSubmitting} onClick={handleTask} variant="contained" color="primary">
            Vérifier données
          </Button>
          &nbsp;
          <FormControlLabel
            control={
              <Checkbox
                checked={isDeepCheck}
                onChange={() => setIsDeepCheck(!isDeepCheck)}
                color="primary"
              />
            }
            label="Deep check (prend du temps!)"
          />
        </div>

        {isSubmitting && <CircularProgress className={classes.loading} />}
        {resultSuccess && <Box bgcolor="success.main">{resultSuccess}</Box>}
        {resultError && <Box bgcolor="error.main">{resultError}</Box>}
      </Paper>
    </>
  )
}

const useCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    loading: {
      marginLeft: theme.spacing(3)
    }
  })
)
