import React from 'react'
import { Box, Container, Grid } from '@material-ui/core'
import Copyright from 'src/components/molecules/Copyright'
import TaskTool from 'src/components/views/task/TaskTool'

export default function TaskView() {
  return (
    <Container maxWidth="lg" className={'classes.container'} style={{ paddingTop: '32px' }}>
      <Grid container spacing={3}>
        <TaskTool
          functionName={'executeEvery5MinutesTasks'}
          title={'Every 5 minutes'}
          content={'Gestion des "sales" et de la quantité restante'}
        />

        <TaskTool
          functionName={'executeEvery15MinutesTasks'}
          title={'Every 15 minutes'}
          content={'Gestion des visible "deals" et envoi des notifications'}
        />

        <TaskTool
          functionName={'executeEvery30MinutesTasks'}
          title={'Every 30 minutes'}
          content={'Calcul des ventes à rembourser par filiales'}
        />

        <TaskTool
          functionName={'executeEvery24HoursTasks'}
          title={'Every 24 heures'}
          content={'Gestion des backups'}
        />
      </Grid>
      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  )
}
