import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import CopyToClipboard from 'src/components/views/reimbursement/CopyToClipboard'
import React from 'react'
import { Sale } from '@yodo/types'
import getNumber from 'src/utils/getNumber'
import getFormattedNumber from 'src/utils/getFormattedNumber'

type Props = {
  sales: Sale[]
}

export default function ListSales({ sales }: Props) {
  let totalPrice = 0
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width={20}>Quantité</TableCell>
            <TableCell align="left">Succursale</TableCell>
            <TableCell align="left">Nom</TableCell>
            <TableCell align="right">Montant encaissé par le commerçant</TableCell>
            <TableCell align="right">Date d'achat</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sales.map((sale: Sale) => {
            totalPrice += getNumber(sale?.priceTotalMerchant)
            return (
              <TableRow key={sale.id}>
                <TableCell>{getNumber(sale?.quantity)}</TableCell>
                <TableCell>{sale.subsidiaryName}</TableCell>
                <TableCell align="left">
                  {sale.dealName} (debug: {sale.id})
                </TableCell>
                <TableCell align="right">{getFormattedNumber(sale?.priceTotalMerchant)}</TableCell>
                <TableCell align="right">
                  {sale.createdAt.toDate().toLocaleDateString('fr-CH')}
                </TableCell>
              </TableRow>
            )
          })}
          <TableRow>
            <TableCell component="th" scope="row" />
            <TableCell component="th" scope="row" />
            <TableCell align="right">
              <strong>Total</strong>
            </TableCell>
            <TableCell align="right">
              <CopyToClipboard text={totalPrice?.toFixed(2)} />
              &nbsp;
              <strong>{totalPrice.toFixed(2)}</strong>
            </TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
