import { buildCollection, EntityCollection } from '@camberi/firecms'
import sponsorsSchema from 'src/collections/sponsor/sponsorSchema'

const sponsorCollection: EntityCollection = buildCollection({
  name: 'Sponsors',
  group: 'Données',
  relativePath: 'sponsors',
  schema: sponsorsSchema,
  properties: ['name', 'dateTimeStart', 'dateTimeEnd', 'message', 'visible', 'image', 'fullWidth'],
  defaultSize: 's'
})

export default sponsorCollection
