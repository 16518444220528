import React, { MouseEventHandler, ReactElement, useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'

type Props = {
  titleText: string
  contentText: string
  actionText: string
  open: boolean
  onPerformAction: MouseEventHandler
  onCloseAction: MouseEventHandler
  content?: ReactElement
  buttonText?: string
}
export default function ConfirmDialog({
  open,
  onPerformAction,
  onCloseAction,
  titleText,
  contentText,
  actionText
}: Props) {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  return (
    <Dialog open={isOpen} onClose={onCloseAction} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{titleText}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseAction}>Annuler</Button>
        <Button
          onClick={(e) => {
            setIsOpen(false)
            onPerformAction(e)
          }}
          color="primary">
          {actionText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
