import React, { useState } from 'react'
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography
} from '@material-ui/core'
import Copyright from 'src/components/molecules/Copyright'
import getFunctionUrl from 'src/utils/getFunctionUrl'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import getFunctionCallable from 'src/utils/getFunctionCallable'

export default function FileView() {
  const classes = useStyles()
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [isIndexingSubmitting, setIsIndexingSubmitting] = useState<true | false>(false)
  const [indexingResultSuccess, setIndexingResultSuccess] = useState<string>('')
  const [indexingResultError, setIndexingResultError] = useState<string>('')

  const [isUsageSubmitting, setIsUsageSubmitting] = useState<true | false>(false)
  const [usageResultSuccess, setUsageResultSuccess] = useState<string>('')
  const [usageResultError, setUsageResultError] = useState<string>('')

  const handleFileIndexing = async () => {
    setIsIndexingSubmitting(true)
    setIndexingResultSuccess('')
    setIndexingResultError('')
    try {
      const url = getFunctionUrl('indexStorage')
      const result = await fetch(url, {
        headers: { 'Content-Type': 'application/json' }
        //body: JSON.stringify({token: 'mytoken'})
      })
      setIsIndexingSubmitting(false)
      setIndexingResultSuccess(JSON.stringify(await result.json(), null, 2))
    } catch (e) {
      console.error(e)
      setIndexingResultError(e.message)
    }
  }

  const handleComputeImageUsage = async () => {
    setUsageResultSuccess('')
    setUsageResultError('')
    const computeImageUsage = getFunctionCallable('computeImageUsage')
    setIsUsageSubmitting(true)
    try {
      // we just extract the ids from the array of users
      // const userIds = selectedUsers.map(({ id }) => id)
      setIsUsageSubmitting(false)
      const result = await computeImageUsage({})
      setUsageResultSuccess(JSON.stringify(result, null, 2))
    } catch (e) {
      console.error(e)
      setUsageResultError(JSON.stringify(e.message, null, 2))
    }
  }

  const urlUploadFirebase = `https://console.firebase.google.com/u/0/project/${process.env['REACT_APP_FIREBASE_PROJECT_ID']}/storage/${process.env['REACT_APP_FIREBASE_PROJECT_ID']}.appspot.com/files`

  return (
    <Container maxWidth="lg" className={'classes.container'} style={{ paddingTop: '32px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={4}>
          <Paper className={'classes.paper'} style={{ padding: '16px' }}>
            <Typography variant="h6" color="textSecondary">
              <Badge
                badgeContent={1}
                color="primary"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}>
                Uploader les fichiers
              </Badge>
            </Typography>
            <div className={classes.container}>
              Utiliser l'inteface d'administration de Firebase pour uploader une image.
            </div>
            <div className={classes.container}>
              <Button
                href={urlUploadFirebase}
                target="_blank"
                variant="contained"
                color="primary"
                rel="noreferrer">
                Ouvrir la console
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <Paper className={'classes.paper'} style={{ padding: '16px' }}>
            <Typography variant="h6" color="textSecondary">
              <Badge
                badgeContent={2}
                color="primary"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}>
                Indexer des fichiers
              </Badge>
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={4}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isIndexingSubmitting}
                  onClick={handleFileIndexing}>
                  Indexer les fichiers
                </Button>
              </Grid>
              <Grid item xs={8}>
                {isIndexingSubmitting && <CircularProgress className={classes.loading} />}
              </Grid>
              <Grid item xs={12}>
                {indexingResultSuccess && <Box bgcolor="success.main">{indexingResultSuccess}</Box>}
                {indexingResultError && <Box bgcolor="error.main">{indexingResultError}</Box>}
              </Grid>
            </Grid>
            <div className={classes.container}>
              On peut lancer "manuellement" l'indexation des fichiers en cliquant sur cette URL
            </div>
            <div className={classes.container}>
              <a href={getFunctionUrl('indexStorage')} target="_blank" rel="noreferrer">
                {getFunctionUrl('indexStorage')}
              </a>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <Paper className={'classes.paper'} style={{ padding: '16px' }}>
            <Typography variant="h6" color="textSecondary">
              Calculer les "usages" des fichiers
            </Typography>
            <div className={classes.container}>
              Le nombre d'utilisation d'une image est calculé dans le champ "usage". Cette valeur
              prévient d'effacer un fichier utilisé par exemple.
            </div>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={4}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isUsageSubmitting}
                  onClick={handleComputeImageUsage}>
                  Récalculer "usage"
                </Button>
              </Grid>

              <Grid item xs={8}>
                {isUsageSubmitting && <CircularProgress className={classes.loading} />}
              </Grid>
              <Grid item xs={12}>
                {usageResultSuccess && <Box bgcolor="success.main">{usageResultSuccess}</Box>}
                {usageResultError && <Box bgcolor="error.main">{usageResultError}</Box>}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    loading: {
      marginLeft: theme.spacing(3)
    }
  })
)
