import { buildSchema } from '@camberi/firecms'
import { Image } from '@yodo/types'
import firebase from 'firebase'

const imageSchema = buildSchema<Image>({
  name: 'Client',
  properties: {
    id: {
      dataType: 'string',
      title: 'Id',
      validation: {
        required: true
      }
    },
    name: {
      dataType: 'string',
      title: 'Name',
      validation: {
        required: true
      }
    },
    identifier: {
      dataType: 'string',
      title: 'Image',
      config: {
        storageMeta: {
          mediaType: 'image',
          storagePath: 'images',
          acceptedFiles: ['image/*'],
          metadata: {
            cacheControl: 'max-age=1000000'
          }
        }
      }
    },
    url: {
      dataType: 'string',
      title: 'Name',
      validation: {
        required: true
      }
    },
    category: {
      dataType: 'reference',
      title: 'Catégorie',
      collectionPath: 'categories'
    },
    createdAt: {
      title: 'Crée le',
      dataType: 'timestamp'
    },
    updatedAt: {
      title: 'Modifié le',
      dataType: 'timestamp'
    }
  },
  defaultValues: {}
})

imageSchema.onSaveSuccess = (props) => {
  // Try logging a few action
  firebase.analytics().logEvent('event_admin_test', props)
  firebase.analytics().logEvent('notification_received')
  firebase.analytics().logEvent('data_updated', props)
  firebase.analytics().logEvent('select_content', props)
  firebase.analytics().logEvent('exception', props)
}

export default imageSchema
