import { CircularProgress, Input } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { config } from '@yodo/config'
import { Deal, Sale } from '@yodo/types'
import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import Title from './Title'

export default function MerchantsSales() {
  const classes = useStyles()

  const [merchants, mloading, merror] = useCollectionData<any>(
    firebase.firestore().collection(config.collection('merchants')),
    {
      idField: 'id'
    }
  )

  const [sales, sloading, serror] = useCollectionData<any>(
    firebase
      .firestore()
      .collection(config.collection('sales'))
      .where('state', 'in', ['payed', 'used']),
    {
      idField: 'id'
    }
  )

  const [dealsByHits, dloading, derror] = useCollectionData<any>(
    firebase.firestore().collection(config.collection('dealsByHits')),
    {
      idField: 'id'
    }
  )

  const [archivedDeals, aloading, aerror] = useCollectionData<any>(
    firebase.firestore().collection(config.collection('archivedDeals')),
    {
      idField: 'id'
    }
  )

  const [deletedDeals, ddloading, dderror] = useCollectionData<any>(
    firebase.firestore().collection(config.collection('deletedDeals')),
    {
      idField: 'id'
    }
  )

  const [data, setData] = useState<Array<any>>([])
  const [dataFilter, setDataFilter] = useState<Array<any>>([])

  useEffect(() => {
    const getDatas = async () => {
      let _data: Array<any> = []
      if (merchants && sales) {
        await merchants.forEach((merchant) => {
          _data.push({
            merchantId: merchant.id,
            merchantName: merchant.name,
            salesNumber: 0,
            salesAmount: 0,
            dealsByHitNumber: 0,
            archivedDealsNumber: 0,
            deletedDealsNumber: 0,
            lastDealDate: null,
            lastSaleDate: null
          })
        })

        await sales.forEach((sale: Sale) => {
          if (!sale.merchant) {
            console.log(sale.id)
            return
          }
          const merchantIndex = _data.findIndex(
            (_merchant) => _merchant.merchantId === sale.merchant!.id
          )

          _data[merchantIndex].salesNumber = _data[merchantIndex].salesNumber + 1
          _data[merchantIndex].salesAmount =
            _data[merchantIndex].salesAmount + sale.priceTotalCustomer

          if (_data[merchantIndex].lastSaleDate) {
            if (sale.createdAt > _data[merchantIndex].lastSaleDate) {
              _data[merchantIndex].lastSaleDate = sale.createdAt
            }
          } else {
            _data[merchantIndex].lastSaleDate = sale.createdAt
          }
        })

        await dealsByHits?.forEach((deal: Deal) => {
          if (!deal.merchant) {
            console.log(deal.id)
            return
          }
          const merchantIndex = _data.findIndex((_deal) => _deal.merchantId === deal.merchant!.id)

          _data[merchantIndex].dealsByHitNumber = _data[merchantIndex].dealsByHitNumber + 1

          if (_data[merchantIndex].lastDealDate) {
            if (deal.createdAt! > _data[merchantIndex].lastDealDate) {
              _data[merchantIndex].lastDealDate = deal.createdAt
            }
          } else {
            _data[merchantIndex].lastDealDate = deal.createdAt
          }
        })

        await deletedDeals?.forEach((deal: Deal) => {
          if (!deal.merchant) {
            console.log(deal.id)
            return
          }
          const merchantIndex = _data.findIndex((_deal) => _deal.merchantId === deal.merchant!.id)

          _data[merchantIndex].deletedDealsNumber = _data[merchantIndex].deletedDealsNumber + 1

          if (_data[merchantIndex].lastDealDate) {
            if (deal.createdAt! > _data[merchantIndex].lastDealDate) {
              _data[merchantIndex].lastDealDate = deal.createdAt
            }
          } else {
            _data[merchantIndex].lastDealDate = deal.createdAt
          }
        })

        await archivedDeals?.forEach((deal: Deal) => {
          if (!deal.merchant) {
            console.log(deal.id)
            return
          }
          const merchantIndex = _data.findIndex((_deal) => _deal.merchantId === deal.merchant!.id)

          if (merchantIndex > -1) {
            _data[merchantIndex].archivedDealsNumber = _data[merchantIndex].archivedDealsNumber + 1

            if (_data[merchantIndex].lastDealDate) {
              if (deal.createdAt! > _data[merchantIndex].lastDealDate) {
                _data[merchantIndex].lastDealDate = deal.createdAt
              }
            } else {
              _data[merchantIndex].lastDealDate = deal.createdAt
            }
          }
        })

        setData(_data)
        setDataFilter(_data)
      }
    }
    getDatas()
  }, [merchants, sales, dealsByHits, archivedDeals, deletedDeals])

  if (merror || serror || derror || aerror || dderror) {
    console.log(merror || serror || derror || dderror)
  }

  if (mloading || sloading || dloading || aloading || ddloading) {
    return <CircularProgress />
  }

  const caseInsensitiveSort = (rowA: any, rowB: any) => {
    const a = rowA.merchantName.toLowerCase().trim()
    const b = rowB.merchantName.toLowerCase().trim()

    if (a > b) {
      return 1
    }

    if (b > a) {
      return -1
    }

    return 0
  }

  const lastSaleDateSort = (rowA: any, rowB: any) => {
    const a = rowA.lastSaleDate
    const b = rowB.lastSaleDate

    if (a > b) {
      return 1
    }

    if (b > a) {
      return -1
    }

    return 0
  }

  const lastDealDateSort = (rowA: any, rowB: any) => {
    const a = rowA.lastDealDate
    const b = rowB.lastDealDate

    if (a > b) {
      return 1
    }

    if (b > a) {
      return -1
    }

    return 0
  }

  const salesAmount = (rowA: any, rowB: any) => {
    const a = rowA.salesAmount
    const b = rowB.salesAmount

    if (a > b) {
      return 1
    }

    if (b > a) {
      return -1
    }

    return 0
  }

  const columns = [
    {
      name: 'Commerçant',
      selector: (row: any) => row.merchantName,
      sortable: true,
      sortFunction: caseInsensitiveSort
    },
    {
      name: '# Offres actives',
      selector: (row: any) => row.dealsByHitNumber,
      sortable: true
    },
    {
      name: '# Offres archivées',
      selector: (row: any) => row.archivedDealsNumber,
      sortable: true
    },
    {
      name: '# Offres supprimées',
      selector: (row: any) => row.deletedDealsNumber,
      sortable: true
    },
    {
      name: '# Ventes',
      selector: (row: any) => row.salesNumber,
      sortable: true
    },
    {
      name: 'CA [CHF]',
      selector: (row: any) => row.salesAmount.toFixed(2),
      sortable: true,
      sortFunction: salesAmount
    },
    {
      name: 'Dernière offre',
      selector: (row: any) =>
        row.lastDealDate ? row.lastDealDate.toDate().toLocaleDateString('fr-CH') : '-',
      sortable: true,
      sortFunction: lastDealDateSort
    },
    {
      name: 'Dernière vente',
      selector: (row: any) =>
        row.lastSaleDate ? row.lastSaleDate.toDate().toLocaleDateString('fr-CH') : '-',
      sortable: true,
      sortFunction: lastSaleDateSort
    }
  ]

  const onChange = (props: any) => {
    const searchTerm = props.target.value
    if (searchTerm) {
      setDataFilter(
        data.filter((row) => row.merchantName.toLowerCase().search(searchTerm.toLowerCase()) >= 0)
      )
    } else {
      setDataFilter(data)
    }
  }

  return (
    <React.Fragment>
      <div className={classes.top}>
        <Title>Ventes par commerçant</Title>
        <div>
          <Input onChange={onChange} placeholder={'Rechercher'} />
        </div>
      </div>
      <DataTable columns={columns} data={dataFilter} pagination dense />
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 30
    }
  })
)
