import { buildCollection, EntityCollection } from '@camberi/firecms'
import merchantSchema, { LightMerchant } from 'src/collections/merchant/merchantSchema'
import subsidiarySchema from 'src/collections/merchant/subsidiarySchema'
import { merchantsSearchDelegate } from 'src/utils/algolia'

const merchantCollection: EntityCollection<LightMerchant> = buildCollection({
  name: 'Commerçants',
  relativePath: 'merchants',
  schema: merchantSchema,
  group: 'Données',
  permissions: ({ user, authController }) => ({
    edit: true,
    create: true,
    delete: true
  }),
  defaultSize: 'm',
  textSearchDelegate: merchantsSearchDelegate,
  excludedProperties: ['createdAt', 'updatedAt'],
  subcollections: [
    {
      relativePath: 'subsidiaries',
      schema: subsidiarySchema,
      name: 'Succursales',
      filterableProperties: ['name']
    }
  ]
})

export default merchantCollection
