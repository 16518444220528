import { buildCollection } from '@camberi/firecms'
import categoriesSchema from 'src/collections/category/categorySchema'

const categoryCollection = buildCollection({
  name: 'Catégories',
  relativePath: 'categories',
  schema: categoriesSchema,
  group: 'Données',
  properties: ['name', 'visible'],
  defaultSize: 'xs'
})

export default categoryCollection
