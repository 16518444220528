import { buildCollection, EntityCollection } from '@camberi/firecms'
import dealSchema, { LightDeal } from 'src/collections/deal/dealSchema'
import imageColumn from 'src/collections/deal/imageColumn'
import { dealsSearchDelegate } from 'src/utils/algolia'

const dealCollection: EntityCollection<LightDeal> = buildCollection({
  name: 'Offres',
  group: 'Données',
  relativePath: 'deals',
  schema: dealSchema,
  permissions: ({ user, authController }) => ({
    edit: true,
    create: false,
    delete: true
  }),
  // indexes: [{ category: "desc", available: "desc" }],
  additionalColumns: [imageColumn],
  textSearchDelegate: dealsSearchDelegate,
  excludedProperties: [
    'image',
    'description',
    'variants',
    'subsidiaries',
    'termsAndConditions',
    'createdAt',
    'updatedAt'
  ],
  defaultSize: 's'
})

export default dealCollection
