import { buildSchema } from '@camberi/firecms'

const regionSchema = buildSchema({
  name: 'Région',
  customId: true,
  properties: {
    value: {
      dataType: 'string',
      title: 'Valeur',
      validation: {
        required: true
      }
    },
    label: {
      dataType: 'string',
      title: 'Libellé',
      validation: {
        required: true
      }
    },
    createdAt: {
      dataType: 'timestamp',
      title: 'Crée le'
    },
    updatedAt: {
      dataType: 'timestamp',
      title: 'Modifié le'
    },
    visible: {
      dataType: 'boolean',
      title: 'Visible'
    }
  }
})

export default regionSchema
