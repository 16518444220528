import { buildCollection } from '@camberi/firecms'
import regionsSchema from 'src/collections/region/regionSchema'

const regionCollection = buildCollection({
  name: 'Régions',
  relativePath: 'regions',
  schema: regionsSchema,
  group: 'Données',
  properties: ['value', 'label', 'visible'],
  defaultSize: 'xs'
})

export default regionCollection
