import { CMSView } from '@camberi/firecms'
import React from 'react'
import ReimbursementView from 'src/components/views/reimbursement/ReimbursementView'

const reimbursementCMSView: CMSView = {
  path: '/reimbursements',
  name: 'Remboursements',
  group: 'Gestion',
  view: <ReimbursementView />
}

export default reimbursementCMSView
