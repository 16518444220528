import { buildSchema } from '@camberi/firecms'
import { Sale } from '@yodo/types'

export interface LightSale
  extends Omit<Sale, 'id' | 'deal' | 'dealName' | 'price' | 'pickupLogs' | 'visible'> {}

const saleSchema = buildSchema<LightSale>({
  name: 'Vente',
  properties: {
    dealData: {
      title: 'Offre',
      dataType: 'map',
      properties: {
        name: {
          dataType: 'string',
          title: 'Nom'
        },
        id: {
          dataType: 'string',
          title: 'id'
        }
      }
    },
    customer: {
      title: 'Client',
      // @ts-ignore
      dataType: 'reference',
      collectionPath: 'users'
      // previewProperties: ['firstName', 'lastName']
    },
    customerFirstName: {
      title: 'Prénom',
      dataType: 'string'
    },
    customerLastName: {
      title: 'Nom',
      dataType: 'string'
    },
    quantity: {
      title: 'Quantité',
      dataType: 'number'
    },
    quantityUsed: {
      title: 'Quantité utilisée',
      dataType: 'number'
    },
    priceTotalCustomer: {
      title: 'Prix total affiché client',
      dataType: 'number'
    },
    priceTotalMerchant: {
      title: 'Prix total encaissé commerçant',
      dataType: 'number'
    },
    paymentMethod: {
      title: 'Méthode de paiement',
      dataType: 'string'
    },
    state: {
      title: 'Statut du paiement',
      dataType: 'string'
    },
    variantId: {
      title: 'ID variant',
      dataType: 'string'
    },
    createdAt: {
      title: 'Crée le',
      dataType: 'timestamp'
    },
    updatedAt: {
      title: 'Modifié le',
      dataType: 'timestamp'
    }
  },
  defaultValues: {}
})

export default saleSchema
