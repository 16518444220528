const configuration = {
    region: 'europe-west6',
    domains: {
        static: {
            dev: 'https://static-dev.yodo.ch',
            prod: 'https://static.yodo.ch',
            quality: 'https://static-quality.yodo.ch'
        },
        images: {
            dev: 'https://static-dev.yodo.ch/images',
            prod: 'https://static.yodo.ch/images',
            quality: 'https://static-quality.yodo.ch/images'
        },
        merchant: {
            dev: 'https://go-dev.yodo.ch',
            prod: 'https://go.yodo.ch',
            quality: 'https://go-quality.yodo.ch'
        },
        admin: {
            dev: 'https://admin-dev.yodo.ch',
            prod: 'https://admin.yodo.ch',
            quality: 'https://admin-quality.yodo.ch'
        }
    },
    monthMaxLimit: 6,
    collections: {
        archivedDeals: 'archivedDeals',
        deletedDeals: 'zDeletedDeals',
        categories: 'categories',
        deals: 'deals',
        dealsByHits: 'dealsByHits',
        images: 'images',
        labels: 'labels',
        loginLogs: 'yLoginLogs',
        notificationLogs: 'yLogNotifications',
        merchants: 'merchants',
        regions: 'regions',
        sales: 'sales',
        pickupLogs: 'pickupLogs',
        sentMessageLogs: 'sentMessageLogs',
        subsidiaries: 'subsidiaries',
        products: 'products',
        users: 'users',
        completedReimbursements: 'completedReimbursements',
        loggedMessages: 'loggedMessages',
        collectionMetadataReports: 'xReportMetadataCollections',
        migrationReports: 'xReportMigrations',
        functionExecutionReports: 'xReportExecutionFunctions',
        taskLogs: 'yLogTasks',
        loginLog: 'yLoginLog',
        userIntegrityReports: 'xReportIntegrityUsers',
        visibleCategories: 'zProjectionVisibleCategories'
    },
    paymentMethods: {
        stripe: 'stripe',
        twint: 'twint'
    },
    topics: {
        pushNotifications: 'push-notifications'
    },
    sales: {
        state: {
            running: 'running',
            expired: 'expired',
            payed: 'payed',
            used: 'used',
            paymentInBrowser: 'paymentInBrowser'
        }
    }
};
export const config = {
    get: (key) => {
        return fetchFromObject(configuration, key);
    },
    collection: (key) => {
        // @ts-ignore
        return config.get(`collections.${key}`);
    },
    topic: (key) => {
        // @ts-ignore
        return config.get(`topics.${key}`);
    },
    getReservedSaleStateArray: () => [
        configuration.sales.state.running,
        configuration.sales.state.payed,
        configuration.sales.state.used,
        configuration.sales.state.paymentInBrowser
    ]
};
function fetchFromObject(obj, key) {
    if (typeof obj === 'undefined') {
        return undefined;
    }
    const index = key.indexOf('.');
    if (index > -1) {
        return fetchFromObject(obj[key.substring(0, index)], key.substr(index + 1));
    }
    return obj[key];
}
