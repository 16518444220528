import React from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import firebase from 'firebase'
import { config } from '@yodo/config'
import { FunctionExecutionReport } from '@yodo/types'
import { Alert } from '@material-ui/lab'

export default function ButtonCheckData() {
  const [executionReport] = useDocumentData<FunctionExecutionReport>(
    // @ts-ignore
    firebase.firestore().doc(`${config.collection('functionExecutionReports')}/every30Minutes`)
  )

  return (
    <>
      Dernière mise à jour des ventes{' '}
      {executionReport
        ? executionReport?.lastExecution.toDate().toLocaleDateString('fr-CH') +
          ' @ ' +
          executionReport?.lastExecution.toDate().toLocaleTimeString('fr-CH')
        : '<inconnu>'}
      <div>
        {executionReport && executionReport?.warnings.length > 0 && (
          <Alert severity="warning">
            A contrôler {executionReport.warnings.length} vente(s)
            <details>
              <summary>Détails</summary>
              {JSON.stringify(executionReport?.warnings)}
            </details>
          </Alert>
        )}
      </div>
    </>
  )
}
