import { buildSchema } from '@camberi/firecms'
import { Deal } from '@yodo/types'
import ImageField from 'src/collections/deal/imageField'
import PriceTextPreview from '../../custom_preview/PriceTextPreview'

export interface LightDeal extends Omit<Deal, 'id' | 'acceptTerms' | 'merchant'> {}

const dealSchema = buildSchema<LightDeal>({
  name: 'Offre',
  properties: {
    name: {
      dataType: 'string',
      title: 'Nom'
    },
    description: {
      dataType: 'string',
      title: 'Description',
      config: {
        multiline: true
      }
    },
    image: {
      title: 'Image',
      // @ts-ignore
      dataType: 'string',
      config: {
        // @ts-ignore
        field: ImageField
      }
    },
    dateTimeStart: {
      dataType: 'timestamp',
      title: "Début de l'offre"
    },
    dateTimeEnd: {
      dataType: 'timestamp',
      title: "Fin de l'offre"
    },
    consumptionDateTimeStart: {
      dataType: 'timestamp',
      title: 'Début validité utilisation'
    },
    consumptionDateTimeEnd: {
      dataType: 'timestamp',
      title: 'Fin validité utilisation'
    },
    quantity: {
      dataType: 'number',
      title: "Nombre d'unité"
    },
    quantityRemaining: {
      dataType: 'number',
      title: "Nombre d'unité restante"
    },
    price: {
      dataType: 'number',
      title: 'Prix avant offre',
      config: {
        preview: PriceTextPreview
      }
    },
    priceWithDiscount: {
      dataType: 'number',
      title: "Prix exclusif, j'encaisse",
      config: {
        preview: PriceTextPreview
      }
    },
    priceFinal: {
      dataType: 'number',
      title: 'Prix affiché pour le client',
      config: {
        preview: PriceTextPreview
      }
    },
    merchantName: {
      dataType: 'string',
      title: 'Commerçant'
    },
    termsAndConditions: {
      dataType: 'string',
      title: 'Condition achat'
    },
    subsidiaries: {
      dataType: 'array',
      title: 'Succursales',
      description: 'Ne pas éditer les valeurs des succursales à ce niveau',
      of: {
        dataType: 'map',
        properties: {
          id: {
            dataType: 'string',
            title: 'Id',
            disabled: true
          },
          name: {
            dataType: 'string',
            title: 'Nom',
            disabled: true
          },
          address: {
            dataType: 'string',
            title: 'Adresse',
            disabled: true
          },
          postalCode: {
            dataType: 'string',
            title: 'Numéro postal',
            disabled: true
          },
          locality: {
            dataType: 'string',
            title: 'Localité',
            disabled: true
          }
        }
      }
    },
    categoryName: {
      dataType: 'string',
      title: 'Catégorie'
    },
    // products: {
    //   dataType: 'array',
    //   title: 'Produits',
    //   of: {
    //     dataType: 'reference',
    //     collectionPath: 'products',
    //     schema: productSchema
    //   }
    // },
    variants: {
      dataType: 'array',
      title: 'Variantes',
      of: {
        dataType: 'map',
        properties: {
          name: {
            dataType: 'string',
            title: 'Nom',
            validation: {
              required: true
            }
          },
          value: {
            dataType: 'string',
            title: 'Valeur'
          },
          quantity: {
            dataType: 'number',
            title: 'Quantité'
          }
        }
      }
    },
    visible: {
      dataType: 'boolean',
      title: 'Visible'
    },
    createdAt: {
      title: 'Crée le',
      dataType: 'timestamp'
    },
    updatedAt: {
      title: 'Modifié le',
      dataType: 'timestamp'
    }
  },
  defaultValues: {
    visible: true
  }
})

export default dealSchema
