import { Box, Button, CircularProgress } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { useState } from 'react'
import ConfirmDialog from 'src/components/molecules/ConfirmDialog'
import getFunctionCallable from 'src/utils/getFunctionCallable'

export default function ButtonSanitizeUsers() {
  const classes = useCardStyles()

  const [isSubmitting, setIsSubmitting] = useState<true | false>(false)
  const [resultSuccess, setResultSuccess] = useState<string>('')
  const [resultError, setResultError] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const handleAction = async () => {
    setResultSuccess('')
    setResultError('')
    const taskHandler = getFunctionCallable('sanitizeUsers')
    setIsSubmitting(true)
    try {
      const result = await taskHandler({})
      setResultSuccess(JSON.stringify(result, null, 2))
      setIsSubmitting(false)
    } catch (e) {
      console.error(e)
      setResultError(JSON.stringify(e.message, null, 2))
      setIsSubmitting(true)
    }
  }

  return (
    <>
      <div className={classes.container}>
        <Button
          disabled={isSubmitting}
          onClick={() => setIsModalOpen(true)}
          variant="contained"
          color="primary">
          Normaliser les données
        </Button>

        <ConfirmDialog
          titleText="Normaliser les données utilisateurs"
          contentText={`Etes-vous sûr de vouloir faire ce changement?`}
          actionText={'Mais oui!'}
          open={isModalOpen}
          onPerformAction={async () => {
            setIsModalOpen(false)
            await handleAction()
          }}
          onCloseAction={() => {
            setIsModalOpen(false)
          }}
        />
      </div>
      {isSubmitting && <CircularProgress className={classes.loading} />}
      {resultSuccess && <Box bgcolor="success.main">{resultSuccess}</Box>}
      {resultError && <Box bgcolor="error.main">{resultError}</Box>}
    </>
  )
}

const useCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1)
    },
    loading: {
      marginLeft: theme.spacing(3)
    }
  })
)
