import { config } from '@yodo/config'
import { isObjectNull } from 'src/utils/isObjectNull'

type Parameters = {
  width?: number
  height?: number
  quality?: number
  keepFormat?: number
}

/**
 *
 */
export function getImageUrl(identifier: string, parameters: Parameters = {}) {
  const serializedParameters = `${isObjectNull(parameters) ? '' : '?'}${parametrize(parameters)}`
  let baseUrl = config.get('domains.images.prod')
  if (process.env.REACT_APP_FIREBASE_PROJECT_ID === 'yodo-club-dev') {
    baseUrl = config.get('domains.images.dev')
  } else if (process.env.REACT_APP_FIREBASE_PROJECT_ID === 'yodo-club-quality') {
    baseUrl = config.get('domains.images.quality')
  }
  return `${baseUrl}/${identifier}${serializedParameters}`
}

/**
 *
 */
const parametrize = (parameters: Parameters) =>
  Object.entries(parameters)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')

/**
 *
 */
export function getImageFallBackUrl() {
  let baseUrl = config.get('domains.images.prod')
  if (process.env.REACT_APP_FIREBASE_PROJECT_ID === 'yodo-club-dev') {
    baseUrl = config.get('domains.images.dev')
  } else if (process.env.REACT_APP_FIREBASE_PROJECT_ID === 'yodo-club-quality') {
    baseUrl = config.get('domains.images.quality')
  }
  return `${baseUrl}/fallback.jpg`
}
