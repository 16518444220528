import { useTheme } from '@material-ui/core/styles'
import { config } from '@yodo/config'
import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import {
  Brush,
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import Title from './Title'

export default function SalesChart() {
  const theme = useTheme()
  const numberOfDays = 180

  const [sales, loading, error] = useCollectionData<any>(
    firebase
      .firestore()
      .collection(config.collection('sales'))
      .orderBy('createdAt', 'asc')
      .where('state', 'in', ['used', 'payed']),
    {
      idField: 'id'
    }
  )

  const [data, setData] = useState<any>([])

  useEffect(() => {
    if (sales) {
      let entries: any = []
      let amount: number = 0
      const minDate = new Date()
      minDate.setDate(minDate.getDate() - numberOfDays)
      sales.map((sale) => {
        amount = amount + sale.priceTotalCustomer
        const saleDate = sale.createdAt.toDate()
        if (saleDate >= minDate) {
          entries.push({
            time: saleDate.toLocaleDateString('fr-CH'),
            amount: amount
          })
        }
        return true
      })
      setData(entries)
    }
  }, [sales])

  if (error) {
    return null
  }

  if (loading) {
    return null
  }

  return (
    <React.Fragment>
      <Title>Ventes</Title>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24
          }}>
          <XAxis dataKey="time" stroke={theme.palette.text.secondary} />
          <YAxis stroke={theme.palette.text.secondary}>
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}>
              CHF
            </Label>
          </YAxis>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip formatter={(value: any) => value.toFixed(2)} />
          <Line
            type="monotone"
            name="CHF"
            dataKey="amount"
            stroke={theme.palette.primary.main}
            dot={false}
          />
          <Brush dataKey="time" height={30} />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  )
}
