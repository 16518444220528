import { AdditionalColumnDelegate } from '@camberi/firecms'
import React from 'react'
import { Button } from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import firebase from 'firebase/app'

// const userAdditionalColumn: AdditionalColumnDelegate<typeof userSchema> = {
const userAdditionalColumn: AdditionalColumnDelegate = {
  id: 'reset_password',
  title: 'Mot de passe',
  builder: (entity) => (
    <Button
      variant="contained"
      disabled={!entity.values.email}
      onClick={async (e) => {
        e.stopPropagation()
        try {
          await firebase.auth().sendPasswordResetEmail(entity.values.email)
          alert(
            `Un email contenant un lien pour réinitialier le mot de passe a été envoyer à ${entity.values.email}`
          )
        } catch (e) {
          console.log(e)
        }
      }}>
      <EmailIcon />
      &nbsp; Réinitialiser
    </Button>
  )
}

export default userAdditionalColumn
