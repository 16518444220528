import { CMSView } from '@camberi/firecms'
import React from 'react'
import SearchIndexView from 'src/components/views/searchIndex/SearchIndexView'

const searchIndexesCMSView: CMSView = {
  path: '/search-indexes',
  name: 'Indexation données',
  group: 'Admin',
  view: <SearchIndexView />
}

export default searchIndexesCMSView
