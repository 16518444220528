import { buildSchema } from '@camberi/firecms'

const labelSchema = buildSchema({
  name: 'Libellé',
  customId: true,
  properties: {
    description: {
      title: 'Description',
      dataType: 'string',
      config: {
        multiline: true
      }
    },
    value: {
      title: 'Valeur',
      dataType: 'string',
      config: {
        multiline: true
      }
    },
    additionalValues: {
      dataType: 'array',
      title: 'Valeurs supplémentaires',
      of: {
        dataType: 'map',
        properties: {
          key: {
            dataType: 'string',
            title: 'Clé',
            validation: {
              required: true
            }
          },
          value: {
            dataType: 'string',
            title: 'Valeur',
            config: {
              multiline: true
            }
          }
        }
      }
    }
  }
})

export default labelSchema
