import { Box, Button, CircularProgress } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Merchant } from '@yodo/types'
import { Sale } from '@yodo/types/dist/Sale'
import React, { useEffect, useState } from 'react'
import ConfirmDialog from 'src/components/molecules/ConfirmDialog'
import getFunctionCallable from 'src/utils/getFunctionCallable'
import getNumber from 'src/utils/getNumber'

type TaskToolType = {
  merchant: Merchant
  sales: Sale[]
}

export default function ButtonReimburse({ sales, merchant }: TaskToolType) {
  const classes = useCardStyles()

  const [isSubmitting, setIsSubmitting] = useState<true | false>(false)
  const [totalPrice, setTotalPrice] = useState<number>(0)
  const [resultSuccess, setResultSuccess] = useState<string>('')
  const [resultError, setResultError] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  useEffect(() => {
    let _totalPrice = 0
    sales.map((sale: Sale) => {
      _totalPrice += getNumber(sale?.priceTotalMerchant)
      return _totalPrice
    })

    setTotalPrice(_totalPrice)
  }, [sales])

  const handleAction = async () => {
    setResultSuccess('')
    setResultError('')
    const taskHandler = getFunctionCallable('markReimbursedSales')
    setIsSubmitting(true)
    try {
      // we just extract the ids from the array of users
      const saleIds = sales.map(({ id }) => id)
      const result = await taskHandler({ merchantId: merchant.id, saleIds: saleIds })
      setResultSuccess(JSON.stringify(result, null, 2))
      setIsSubmitting(false)
    } catch (e) {
      console.error(e)
      setResultError(JSON.stringify(e.message, null, 2))
      setIsSubmitting(true)
    }
  }

  return (
    <>
      <div className={classes.container}>
        <Button
          disabled={isSubmitting}
          onClick={() => setIsModalOpen(true)}
          variant="contained"
          color="primary">
          "{merchant?.name}" a été remboursé
        </Button>

        <ConfirmDialog
          titleText="Remboursement"
          contentText={`Etes-vous sûr que le remboursement de ${totalPrice} CHF a été fait pour le commerçant ${merchant?.name}?`}
          actionText={'Marquer comme remboursé'}
          open={isModalOpen}
          onPerformAction={async () => {
            setIsModalOpen(false)
            await handleAction()
          }}
          onCloseAction={() => {
            setIsModalOpen(false)
          }}
        />
      </div>
      {isSubmitting && <CircularProgress className={classes.loading} />}
      {resultSuccess && <Box bgcolor="success.main">{resultSuccess}</Box>}
      {resultError && <Box bgcolor="error.main">{resultError}</Box>}
    </>
  )
}

const useCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1)
    },
    loading: {
      marginLeft: theme.spacing(3)
    }
  })
)
