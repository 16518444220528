import Typography from '@material-ui/core/Typography'
import { config } from '@yodo/config'
import firebase from 'firebase'
import React from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import Title from './Title'

export default function Numbers() {
  const [sales] = useCollectionData<any>(
    firebase
      .firestore()
      .collection(config.collection('sales'))
      .where('state', 'in', ['used', 'payed']),
    {
      idField: 'id'
    }
  )

  const [accounts] = useCollectionData<any>(
    firebase
      .firestore()
      .collection(config.collection('users'))
      .where('updatedAt', '!=', null)
      .where('roles', 'array-contains', 'customer'),
    {
      idField: 'id'
    }
  )

  const [deals] = useCollectionData<any>(
    firebase.firestore().collection(config.collection('dealsByHits')),
    {
      idField: 'id'
    }
  )

  const [merchants] = useCollectionData<any>(
    firebase.firestore().collection(config.collection('merchants')).where('visible', '==', true),
    {
      idField: 'id'
    }
  )

  return (
    <React.Fragment>
      <div>
        <Title>Nombre de ventes</Title>
        <Typography component="p" variant="h4">
          {sales?.length}
        </Typography>
      </div>
      <div>
        <Title>Nombre d'offres actives</Title>
        <Typography component="p" variant="h4">
          {deals?.length}
        </Typography>
      </div>
      <div>
        <Title>Nombre de comptes</Title>
        <Typography component="p" variant="h4">
          {accounts?.length}
        </Typography>
      </div>
      <div>
        <Title>Nombre de commerçants</Title>
        <Typography component="p" variant="h4">
          {merchants?.length}
        </Typography>
      </div>
    </React.Fragment>
  )
}
