import { AdditionalColumnDelegate } from '@camberi/firecms'
import React from 'react'
import { getImageUrl } from 'src/utils/getImageUrl'

//const imageColumn: AdditionalColumnDelegate<typeof dealSchema> = {
const imageColumn: AdditionalColumnDelegate = {
  id: 'custom-image',
  title: 'Image',
  builder: (entity) => {
    const url = entity.values?.image?.identifier
      ? getImageUrl(entity.values.image.identifier, { width: 100 })
      : undefined
    return (
      url && (
        <div>
          <img src={url} alt={`${entity.values?.image?.identifier}`} />
        </div>
      )
    )
  }
}

export default imageColumn
