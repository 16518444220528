import i18next from 'i18next'
import common_fr from './translations/fr/common.json'
import common_en from './translations/en/common.json'

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'fr', // language to use
  resources: {
    fr: {
      translation: common_fr
    },
    en: {
      translation: common_en
    }
  }
})
