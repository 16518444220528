import { buildCollection, EntityCollection } from '@camberi/firecms'
import { Image } from '@yodo/types'
import imageSchema from 'src/collections/image/imageSchema'

const imageCollection: EntityCollection<Image> = buildCollection({
  name: 'Images',
  relativePath: 'images',
  schema: imageSchema,
  group: 'Données',
  permissions: ({ user, authController }) => ({
    edit: true,
    create: true,
    delete: true
  }),
  excludedProperties: ['createdAt', 'updatedAt'],
  defaultSize: 'm'
})

export default imageCollection
