import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { config } from '@yodo/config'
import firebase from 'firebase'
import React from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import getFormattedNumber from 'src/utils/getFormattedNumber'
import Title from './Title'

export default function Orders() {
  const [sales, loading, error] = useCollectionData<any>(
    firebase
      .firestore()
      .collection(config.collection('sales'))
      .orderBy('createdAt', 'desc')
      .where('state', 'in', ['used', 'payed'])
      .limit(10),
    {
      idField: 'id'
    }
  )

  if (error) {
    console.log(error)
  }

  if (loading) {
    return <div>...</div>
  }

  return (
    <React.Fragment>
      <Title>Dernières ventes</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Offre</TableCell>
            <TableCell>Marchand</TableCell>
            <TableCell>Quantité</TableCell>
            <TableCell>Méthode de paiement</TableCell>
            <TableCell align="right">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sales?.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.createdAt.toDate().toLocaleDateString('fr-CH')}</TableCell>
              <TableCell>{row.dealName}</TableCell>
              <TableCell>{row.dealData.merchantName ?? row.subsidiaryName}</TableCell>
              <TableCell>{row.quantity}</TableCell>
              <TableCell>{row.paymentMethod}</TableCell>
              <TableCell align="right">{getFormattedNumber(row.priceTotalCustomer)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  )
}
