import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { FieldProps } from '@camberi/firecms'
import { getImageUrl } from 'src/utils/getImageUrl'

interface CustomColorTextFieldProps {
  color: string
}

export default function ImageField({
  property,
  value,
  setValue,
  customProps,
  touched,
  error,
  isSubmitting,
  context, // the rest of the entity values here
  ...props
}: FieldProps<string, CustomColorTextFieldProps>): ReactElement {
  // @ts-ignore
  const identifier = value?.identifier ?? ''
  let url = identifier ? getImageUrl(identifier, { width: 1000 }) : undefined

  return url ? (
    <Card>
      <CardActionArea>
        <CardMedia component="img" alt={url} image={url} />
      </CardActionArea>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          URL
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          <a href={url} target={'_blank'} rel="noreferrer">
            {url}
          </a>
        </Typography>
        <Typography gutterBottom variant="h5" component="h2" style={{ paddingTop: 10 }}>
          Identifier
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {identifier}
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <div>Pas d'image associé</div>
  )
}
