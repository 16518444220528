import { useTheme } from '@material-ui/core/styles'
import { config } from '@yodo/config'
import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import {
  Brush,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import Title from './Title'

export default function UserChart() {
  const theme = useTheme()
  const numberOfDays = 180

  const [users, loading, error] = useCollectionData<any>(
    firebase
      .firestore()
      .collection(config.collection('users'))
      .where('updatedAt', '!=', null)
      .where('roles', 'array-contains', 'customer'),
    {
      idField: 'id'
    }
  )

  const [data, setData] = useState<any>([])

  useEffect(() => {
    if (users) {
      let entries: any = []
      let count = 0
      const minDate = new Date()
      minDate.setDate(minDate.getDate() - numberOfDays)
      users.map((user, index) => {
        count = count + 1
        const userDate = user.updatedAt.toDate()
        if (userDate >= minDate) {
          entries.push({ time: userDate.toLocaleDateString('fr-CH'), count })
        }
        return true
      })
      setData(entries)
    }
  }, [users])

  if (error) {
    console.log(error)
    return null
  }

  if (loading) {
    return null
  }

  return (
    <React.Fragment>
      <Title>Utilisateurs</Title>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0
          }}>
          <XAxis dataKey="time" stroke={theme.palette.text.secondary} />
          <YAxis stroke={theme.palette.text.secondary} />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Line
            name="Utilisateurs"
            type="monotone"
            dataKey="count"
            stroke={theme.palette.primary.main}
            dot={false}
          />
          <Brush dataKey="time" height={30} />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  )
}
