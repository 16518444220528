import { buildSchema } from '@camberi/firecms'
import { Merchant } from '@yodo/types'
import RegionField from 'src/collections/merchant/regionField'

export interface LightMerchant
  extends Omit<
    Merchant,
    | 'id'
    | 'completedReimbursements'
    | 'pendingReimbursementNumber'
    | 'numberOfPendingReimbursements'
  > {}

const merchantSchema = buildSchema<LightMerchant>({
  name: 'Commerçant',
  properties: {
    name: {
      title: 'Nom',
      dataType: 'string',
      validation: {
        required: true
      }
    },
    subsidiaries: {
      title: 'Succursales',
      dataType: 'array',
      of: {
        dataType: 'map',
        previewProperties: ['name', 'locality', 'region'],
        properties: {
          name: {
            title: 'Nom',
            dataType: 'string',
            validation: {
              required: true
            }
          },
          address: {
            title: 'Adresse',
            dataType: 'string',
            config: {
              multiline: true
            }
          },
          postalCode: {
            title: 'Numéro postal',
            dataType: 'string'
          },
          locality: {
            title: 'Localité',
            dataType: 'string'
          },
          region: {
            title: 'Région',
            dataType: 'string',
            config: {
              field: RegionField
            }
          }
        }
      }
    },
    showClientPersonalData: {
      title: 'Affiche les données personnelles client',
      description: 'Affiche les données personnelles client dans la console commerçant',
      dataType: 'boolean'
    },
    visible: {
      title: 'Visible',
      dataType: 'boolean'
    },
    createdAt: {
      title: 'Crée le',
      dataType: 'timestamp'
    },
    updatedAt: {
      title: 'Modifié le',
      dataType: 'timestamp'
    }
  },
  defaultValues: {
    visible: true
  }
})

export default merchantSchema
