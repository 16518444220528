import React from 'react'
import { Merchant } from '@yodo/types'
import { List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import CopyToClipboard from 'src/components/views/reimbursement/CopyToClipboard'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Subsidiary } from '@yodo/types/dist/Merchant'

type Props = {
  merchant: Merchant
}
export default function ListSubsidiaries({ merchant }: Props) {
  const classes = useStyles()
  return (
    <List dense className={classes.root}>
      {Array.isArray(merchant?.subsidiaries) &&
        merchant?.subsidiaries?.map((subsidiary: Subsidiary) => {
          return (
            <ListItem key={subsidiary.name} button>
              <ListItemText
                id={subsidiary.iban}
                primary={`${subsidiary.name} - IBAN: ${subsidiary.iban}`}
              />
              <ListItemSecondaryAction>
                <CopyToClipboard text={subsidiary.iban} />
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
    </List>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 560,
      marginTop: theme.spacing(3)
      // backgroundColor: theme.palette.background.paper
    }
  })
)
