import {
  Authenticator,
  CMSApp,
  Navigation,
  NavigationBuilder,
  NavigationBuilderProps
} from '@camberi/firecms'
import firebase from 'firebase'
import i18next from 'i18next'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import categoryCollection from 'src/collections/category'
import dealCollection from 'src/collections/deal'
import imageCollection from 'src/collections/image'
import labelCollection from 'src/collections/label'
import merchantCollection from 'src/collections/merchant'
import regionCollection from 'src/collections/region'
import saleCollection from 'src/collections/sale'
import sponsorCollection from 'src/collections/sponsor'
import userCollection from 'src/collections/user'
import {
  fileCMSView,
  integrityCMSView,
  migrationCMSView,
  notificationCMSView,
  reimbursementCMSView,
  searchIndexesCMSView,
  statisticCMSView,
  taskCMSView
} from 'src/components/views/index'
import { firebaseConfig } from './firebaseConfig'
// import 'src/utils/console'
import './i18next-init'
import logo from './images/yodo_logo.png'
import * as serviceWorker from './serviceWorker'

const myAuthenticator: Authenticator = (user?: firebase.User) => {
  console.log('Allowing access to', user?.email)
  const remoteConfig = firebase.remoteConfig()
  return remoteConfig
    .fetchAndActivate()
    .then<boolean>(() => {
      // const adminEmails: string[] = String(process.env.REACT_APP_ADMIN_EMAILS).split(',')
      const adminEmails = JSON.parse(remoteConfig.getString('adminEmails'))
      const email = user?.email ?? ''

      // Test analytics
      firebase.analytics().logEvent('login', {
        method: 'login',
        from: 'admin.yodo.ch'
      })
      return adminEmails.includes(email)
    })
    .catch<boolean>((err) => {
      console.error('Firebase Remote Config failed to initialize', err)
      return false
    })
}

const onFirebaseInit = (config: Object) => {
  if (process.env.REACT_APP_CONTEXT === 'local') {
    console.log('Data from local emulator!')

    // Firestore
    firebase.firestore().useEmulator('localhost', 3600)

    // Cloud Function
    firebase.app().functions().useEmulator('localhost', 5001)

    // Auth
    // firebase.auth().useEmulator('http://localhost:9099');
  }
}

const navigation: NavigationBuilder = ({ user }: NavigationBuilderProps) => {
  const navigation: Navigation = {
    collections: [
      categoryCollection,
      merchantCollection,
      imageCollection,
      labelCollection,
      dealCollection,
      regionCollection,
      userCollection,
      sponsorCollection,
      saleCollection
    ],
    views: [
      reimbursementCMSView,
      notificationCMSView,
      statisticCMSView,
      // backupCMSView,
      searchIndexesCMSView,
      fileCMSView,
      integrityCMSView,
      migrationCMSView,
      taskCMSView
    ]
  }

  return navigation
}

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Suspense fallback="loading">
      <CMSApp
        name={`Yodo Club Admin ${
          process.env.REACT_APP_CONTEXT === 'local'
            ? `(${process.env.REACT_APP_CONTEXT})`
            : process.env.REACT_APP_APPLICATION_CONTEXT_NAME
        }`}
        authentication={myAuthenticator}
        signInOptions={[
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.EmailAuthProvider.PROVIDER_ID
        ]}
        allowSkipLogin={false}
        logo={logo}
        navigation={navigation}
        onFirebaseInit={onFirebaseInit}
        // In the production environment, the configuration is fetched from the environment automatically
        firebaseConfig={process.env.NODE_ENV !== 'production' ? firebaseConfig : undefined}
        toolbarExtraWidget={<></>}
      />
    </Suspense>
  </I18nextProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorker.unregister()
