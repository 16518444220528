import { buildSchema } from '@camberi/firecms'

const sponsorSchema = buildSchema({
  name: 'Sponsor',
  // customId: true,
  properties: {
    name: {
      dataType: 'string',
      title: 'Nom',
      validation: {
        required: true
      }
    },
    image: {
      dataType: 'string',
      title: 'Image',
      config: {
        storageMeta: {
          mediaType: 'image',
          storagePath: 'sponsors',
          acceptedFiles: ['image/*'],
          metadata: {
            cacheControl: 'max-age=1000000'
          }
        }
      },
      description: 'Uploader une image correspondant à votre sponsor'
    },
    message: {
      dataType: 'string',
      title: 'Message',
      validation: {
        required: false
      }
    },
    visible: {
      dataType: 'boolean',
      title: 'Visible'
    },
    dateTimeStart: {
      dataType: 'timestamp',
      title: 'Affichage du'
    },
    dateTimeEnd: {
      dataType: 'timestamp',
      title: 'Affichage au'
    },
    website: {
      dataType: 'string',
      title: 'Site web',
      config: {
        url: true
      }
    },
    fullWidth: {
      dataType: 'boolean',
      title: 'Pleine largeur'
    }
  },
  defaultValues: {
    visible: true
  }
})

export default sponsorSchema
