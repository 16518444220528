import { Box, Container, Grid, Paper } from '@material-ui/core'
import React from 'react'
import Copyright from 'src/components/molecules/Copyright'
import MerchantsSales from 'src/components/views/statistic/MerchantsSales'
import Numbers from './Numbers'
import Orders from './Orders'
import SalesChart from './SalesChart'
import UserChart from './UsersChart'

export default function StatisticView() {
  // const classes = useStyles();
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <Container maxWidth="lg" className={'classes.container'} style={{ paddingTop: '32px' }}>
      <Grid container spacing={3}>
        {/* Numbers */}
        <Grid item xs={12}>
          <Paper
            className={'fixedHeightPaper'}
            style={{
              padding: '16px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              textAlign: 'center'
            }}>
            <Numbers />
          </Paper>
        </Grid>
        {/* Sales Chart */}
        <Grid item xs={12}>
          <Paper
            className={'fixedHeightPaper'}
            style={{
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              height: 300
            }}>
            <SalesChart />
          </Paper>
        </Grid>
        {/* Chart */}
        <Grid item xs={12}>
          <Paper
            className={'fixedHeightPaper'}
            style={{
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              height: 300
            }}>
            <UserChart />
          </Paper>
        </Grid>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper className={'classes.paper'} style={{ padding: '16px' }}>
            <Orders />
          </Paper>
        </Grid>
        {/* Merchant's sales */}
        <Grid item xs={12}>
          <Paper className={'classes.paper'} style={{ padding: '16px' }}>
            <MerchantsSales />
          </Paper>
        </Grid>
      </Grid>
      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  )
}
