import React from 'react'
import { Button, Tooltip } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'

type Props = {
  text: string | number
}

export default function CopyToClipboard({ text }: Props) {
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false)
  return (
    <Tooltip
      open={showTooltip}
      title={'Copié'}
      leaveDelay={1500}
      onClose={() => setShowTooltip(false)}>
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={async () => {
          setShowTooltip(true)
          await navigator.clipboard.writeText(`${text}`)
        }}>
        <FileCopyIcon />
      </Button>
    </Tooltip>
  )
}
