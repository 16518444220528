import { buildSchema } from '@camberi/firecms'

const categorySchema = buildSchema({
  name: 'Category',
  // customId: true,
  properties: {
    name: {
      dataType: 'string',
      title: 'Nom',
      validation: {
        required: true
      }
    },
    image: {
      dataType: 'string',
      title: 'Image',
      config: {
        storageMeta: {
          mediaType: 'image',
          storagePath: 'categories',
          acceptedFiles: ['image/*'],
          metadata: {
            cacheControl: 'max-age=1000000'
          }
        }
      },
      description: 'Uploader une image correspondant à votre catégorie'
    },
    visible: {
      dataType: 'boolean',
      title: 'Visible'
    },
    createdAt: {
      dataType: 'timestamp',
      title: 'Crée le'
    },
    updatedAt: {
      dataType: 'timestamp',
      title: 'Modifié le'
    }
  },
  defaultValues: {
    visible: true
  }
})

export default categorySchema
