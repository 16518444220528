import React, { useState } from 'react'
import getFunctionCallable from 'src/utils/getFunctionCallable'
import { Box, Button, CircularProgress, Grid, Paper, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

type TaskToolType = {
  functionName: string
  title: string
  content: string
}

export default function TaskTool({ functionName, title, content }: TaskToolType) {
  const classes = useStyles()

  const [isSubmitting, setIsSubmitting] = useState<true | false>(false)
  const [resultSuccess, setResultSuccess] = useState<string>('')
  const [resultError, setResultError] = useState<string>('')

  const handleTask = async () => {
    setResultSuccess('')
    setResultError('')
    const taskHandler = getFunctionCallable(functionName)
    setIsSubmitting(true)
    try {
      // we just extract the ids from the array of users
      // const userIds = selectedUsers.map(({ id }) => id)
      const result = await taskHandler({})
      setResultSuccess(JSON.stringify(result, null, 2))
      setIsSubmitting(false)
    } catch (e) {
      console.error(e)
      setResultError(JSON.stringify(e.message, null, 2))
      setIsSubmitting(true)
    }
  }

  return (
    <>
      <Grid item xs={12} md={6} lg={6}>
        <Paper className={'classes.paper'} style={{ padding: '16px' }}>
          <Typography variant="h6" color="textSecondary">
            {title}
          </Typography>
          <div className={classes.container}>{content}</div>
          <div className={classes.container}>
            <Button
              disabled={isSubmitting}
              onClick={handleTask}
              variant="contained"
              color="primary">
              Exécuter tâche
            </Button>
          </div>

          {isSubmitting && <CircularProgress className={classes.loading} />}
          {resultSuccess && <Box bgcolor="success.main">{resultSuccess}</Box>}
          {resultError && <Box bgcolor="error.main">{resultError}</Box>}
        </Paper>
      </Grid>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    loading: {
      marginLeft: theme.spacing(3)
    }
  })
)
