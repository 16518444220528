import { FieldProps } from '@camberi/firecms'
import { ListItemText, MenuItem, Select } from '@material-ui/core'
import { config } from '@yodo/config'
import firebase from 'firebase'
import React, { ReactElement, useEffect, useState } from 'react'
interface CustomRegionsFieldProps {}

export default function RegionField(
  props: FieldProps<string, CustomRegionsFieldProps>
): ReactElement {
  const [loading, setLoading] = useState(true)
  const [regions, setRegions] = useState<Array<any>>([])

  useEffect(() => {
    const getData = async () => {
      const regions = await firebase.firestore().collection(config.collection('regions')).get()
      let _regions = []
      for await (const regionDoc of regions.docs) {
        _regions.push(regionDoc.data())
      }

      setRegions(_regions)
    }

    getData().finally(() => setLoading(false))
  }, [])

  if (loading) {
    return <></>
  }

  if (!regions.length) {
    return <div>Aucune région disponible</div>
  }

  return (
    <Select
      id={props.name}
      labelId={props.name}
      value={props.value}
      autoWidth={true}
      style={{ width: '100%', padding: 10 }}
      onChange={(evt: any) => {
        props.setValue(evt.target.value)
      }}>
      {regions.map((region) => (
        <MenuItem key={region.id} value={region.value}>
          <ListItemText primary={region.label} />
        </MenuItem>
      ))}
    </Select>
  )
}
