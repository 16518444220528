import {
  Box,
  Checkbox,
  Container,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { DataGrid, GridColDef } from '@material-ui/data-grid'
import { config } from '@yodo/config'
import firebase from 'firebase'
import React, { useEffect } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import Copyright from 'src/components/molecules/Copyright'
import ButtonRemoveDuplicateUsers from 'src/components/views/integrity/ButtonRemoveDuplicateUsers'
import ButtonRemoveStripeCustomerId from 'src/components/views/integrity/ButtonRemoveStripeCustomerId'
import ButtonSanitizeUsers from 'src/components/views/integrity/ButtonSanitizeUsers'
import IntegrityCheckCard from 'src/components/views/integrity/IntegrityCheckCard'

export default function DataIntegrityView() {
  const classes = useStyles()

  // Fetch all user integrity reports
  const [integrityReports, integrityReportLoading] = useCollectionData<any>(
    firebase
      .firestore()
      .collection(config.collection('userIntegrityReports'))
      .where('hasErrors', '==', true),
    {
      idField: 'id'
    }
  )

  const [filters, setFilters] = React.useState<string[]>([])
  const [filteredIntegrityReports, setFilteredIntegrityReports] = React.useState<object[]>([])

  useEffect(() => {
    if (Array.isArray(integrityReports)) {
      if (filters.length > 0) {
        const _filteredIntegrityReports: object[] = []
        filters.map((filter) => {
          integrityReports.map((integrityReport) => {
            if (integrityReport?.errors.includes(filter)) {
              const isFound = _filteredIntegrityReports.findIndex(
                (x: any) => x.id === integrityReport.id
              )
              if (isFound === -1) {
                _filteredIntegrityReports.push(integrityReport)
              }
            }
            return integrityReport
          })

          return integrityReports
        })
        setFilteredIntegrityReports(_filteredIntegrityReports)
      } else {
        setFilteredIntegrityReports(integrityReports)
      }
    } else {
      setFilteredIntegrityReports([])
    }
  }, [integrityReports, filters])

  const errorTypes = [
    'duplicate-user-email',
    'duplicate-user-phone-number',
    'duplicate-account-email',
    'duplicate-account-phone-number',
    'failed-function-update',
    'failed-function-send-notification',
    'missing-fcm-token',
    'missing-user',
    'missing-account',
    'not-matching-display-name',
    'not-matching-email',
    'not-matching-phone-number'
  ]

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilters(event.target.value as string[])
  }

  const MenuProps = {
    PaperProps: {
      style: {
        width: 500
      }
    }
  }

  return (
    <Container maxWidth="lg" className={'classes.container'} style={{ paddingTop: '32px' }}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper className={'classes.paper'} style={{ padding: '16px' }}>
            <Typography variant="h6" color="textSecondary" className={classes.gridHeader}>
              Supprimer les utilisateurs doublons
            </Typography>
            Supprimer les utilisateurs doublons dans le système en particulier les utilisateurs
            anonymes. Résout le bug de la double notification.
            <ButtonRemoveDuplicateUsers />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={'classes.paper'} style={{ padding: '16px' }}>
            <Typography variant="h6" color="textSecondary" className={classes.gridHeader}>
              Nettoyer les données utilisateurs
            </Typography>
            Normaliser les propriétés firstName, lastName et displayName
            <ButtonSanitizeUsers />
          </Paper>
        </Grid>
        {process.env.REACT_APP_FIREBASE_PROJECT_ID === 'yodo-club-dev' && (
          <Grid item xs={6}>
            <Paper className={'classes.paper'} style={{ padding: '16px' }}>
              <Typography variant="h6" color="textSecondary" className={classes.gridHeader}>
                Nettoyer stripe
              </Typography>
              Supprimer les ids Stripe des utilisateurs
              <ButtonRemoveStripeCustomerId />
            </Paper>
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper className={'classes.paper'} style={{ padding: '16px' }}>
            <Typography variant="h6" color="textSecondary" className={classes.gridHeader}>
              Rapport d'intégrité des utilisateurs
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={8}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="error-types">Type d'erreur</InputLabel>
                  <Select
                    labelId="error-types"
                    id="error-types"
                    multiple
                    autoWidth={true}
                    value={filters}
                    onChange={handleChange}
                    input={<Input />}
                    renderValue={(selected) => (selected as string[]).join(', ')}
                    MenuProps={MenuProps}>
                    {errorTypes.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={filters.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <IntegrityCheckCard functionName={'checkUserIntegrity'} />
              </Grid>
            </Grid>

            <div style={{ height: 900, width: '100%' }}>
              <DataGrid
                rows={filteredIntegrityReports}
                columns={columns}
                pageSize={100}
                loading={integrityReportLoading}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridHeader: {
      marginTop: theme.spacing(3)
    },
    formControl: {
      margin: theme.spacing(1),
      width: '100%'
      // maxWidth: 500
    }
  })
)

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'accountId / userId',
    width: 300
    // align: 'left',
    // headerAlign: 'left'
  },
  {
    field: 'errors',
    headerName: 'errors',
    width: 500
  },
  {
    field: 'userRoles',
    headerName: 'userRoles',
    width: 150
  },
  {
    field: 'userDisplayName',
    headerName: 'userDisplayName',
    width: 150
  },
  {
    field: 'accountDisplayName',
    headerName: 'accountDisplayName',
    width: 150
  },
  {
    field: 'userEmail',
    headerName: 'userEmail',
    width: 150
  },
  {
    field: 'accountEmail',
    headerName: 'accountEmail',
    width: 150
  }
]
